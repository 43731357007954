import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                nav: 'toggleNav',
                cart: 'toggleCart',
                toggleAccessibility: 'toggleAccessibility',
            }
        }
    }

    init() {

    }

    toggleNav(e) {
        const burger = this.$('nav')[0];
        // const text = burger.querySelector('.-hover');
        const html = document.querySelector('html');
        if(html.classList.contains('has-navOpen')) {
            html.classList.remove('has-navOpen');
            burger.classList.remove('is-active');
            // text.textContent = text.dataset.texthover;
        } else {
            html.classList.add('has-navOpen');
            burger.classList.add('is-active');
            // text.textContent = text.dataset.textclose;
        }
    }

    toggleAccessibility() {
        $('#pojo-a11y-toolbar').toggleClass('pojo-a11y-toolbar-open');
        $('.c-header_accessibility').toggleClass('is-active');
    }
}
