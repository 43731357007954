import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                play: 'play',
            }
        }
    }

    init() {

    }

    play(e) {
        const target = e.currentTarget;
        const type = this.getData('type');
        $(target).addClass('is-hidden');

        if(type == 'embed') {
            $(this.el).find('iframe')[0].src += "&amp;autoplay=1";
        }else{
            $(this.el).find('video')[0].play();
        }
    }
}
